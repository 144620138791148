import React from "react";
import isoImg1 from "../../assets/images/about-us/iso-9001.png";
import isoImg2 from "../../assets/images/about-us/iso-iec-27001.png";
import isoImg3 from "../../assets/images/about-us/iso-iec-20000.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const Certification = () => {
  const { t } = useTranslation();
  return (
    <section className="m-certification m-tabPanel">
      <div className="_wr">
        <div className="_w">
          <div className="m-certification__text _l5">
            <h2 className="-thin">{t("aboutUs.certification.headline")}</h2>
            <span className="a-line -red"></span>
            <div className="m-certification__top">
              <p>{t("aboutUs.certification.firstText")}</p>
              <p>{t("aboutUs.certification.secondText")}</p>
              <p>
                <a href="/static/assets/politika_IMSa.pdf" target="_blank">
                  {t("aboutUs.certification.thirdText")}
                </a>
              </p>
            </div>
          </div>
          <div className="m-certification__blocks ofs_l1 _l6">
            <Link
              to="https://www.sgs.com/en/certified-clients-and-products#590"
              target="_blank"
              className="m-certification__blocks--block"
            >
              <div className="m-certification__blocks--image">
                <img src={isoImg1} alt="" />
              </div>
              <div className="m-certification__blocks--text">
                <h3>ISO 9001</h3>
                <p>{t("aboutUs.certification.firstIso")}</p>
              </div>
            </Link>
            <Link
              to="https://www.sgs.com/en/certified-clients-and-products#590"
              target="_blank"
              className="m-certification__blocks--block"
            >
              <div className="m-certification__blocks--image">
                <img src={isoImg2} alt="" />
              </div>
              <div className="m-certification__blocks--text">
                <h3>ISO/IEC 27001</h3>
                <p>{t("aboutUs.certification.secondIso")}</p>
              </div>
            </Link>
            <Link
              to="https://www.sgs.com/en/certified-clients-and-products#590"
              target="_blank"
              className="m-certification__blocks--block"
            >
              <div className="m-certification__blocks--image">
                <img src={isoImg3} alt="" />
              </div>
              <div className="m-certification__blocks--text">
                <h3>ISO/IEC 20000 </h3>
                <p>{t("aboutUs.certification.thirdIso")}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
